import React from "react"
import Header from "../components/header"
import GlobalStyle from "../styles/global-style"
import { ThemeProvider } from "styled-components"
import DefaultTheme from "../styles/default-theme"
import styled from "styled-components"

const ContentWrapper = styled.div`
  padding-top: 50px;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    padding-top: 70px;
  }
`
const MainLayout = ({ children }) => (
  <>
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyle />
      <Header />
      <ContentWrapper>{children}</ContentWrapper>
    </ThemeProvider>
  </>
)

export default MainLayout
