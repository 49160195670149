import React from "react"
import styled from "styled-components"
import StyledLink from "./styled-link"
import Menu from "./menu"
import Button from "./button"

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
  background: ${props => props.theme.colors.background};
  z-index: 1;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    height: 70px;
  }
`

const Logo = styled.span`
  font-size: ${props => props.theme.fontSizes.big};
  white-space: nowrap;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    font-size: ${props => props.theme.fontSizes.headerMedium};
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`

const NavList = styled.ul`
  display: none;
  width: 400px;
  list-style: none;
  font-size: ${props => props.theme.fontSizes.regular};

  @media (min-width: ${props => props.theme.breakPoints.sideMenu}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${props => props.theme.maxWidth}) {
    width: 420px;
  }
`

const StyledButton = styled(Button)`
  min-width: initial;
  border: none;

  :hover {
    border: none;
  }
`

const Header = () => (
  <Wrapper>
    <Nav>
      <StyledLink to={`/`}>
        <Logo>Aleksandra Styczeń</Logo>
      </StyledLink>
      <NavList>
        <li>
          <StyledLink to={`/`} activeClassName={"active"}>
            Strona główna
          </StyledLink>
        </li>
        <li>
          <StyledLink to={`/about`} activeClassName={"active"}>
            O mnie
          </StyledLink>
        </li>
        <li>
          <StyledLink to={`/offer`} activeClassName={"active"}>
            Oferta
          </StyledLink>
        </li>
        <li>
          <StyledLink to={`#contact`} activeClassName={"active"}>
            <StyledButton trackingId="Navbar - Make an Appointment">
              Umów wizytę
            </StyledButton>
          </StyledLink>
        </li>
      </NavList>
      <Menu />
    </Nav>
  </Wrapper>
)

export default Header
