import styled from "styled-components"

const SectionHeader = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.headerLarge};
  text-align: center;
  margin: 50px 0;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    font-size: ${props => props.theme.fontSizes.headerLarge};
  }
`

export default SectionHeader
