import React, { useState } from "react"
import styled from "styled-components"
import StyledLink from "./styled-link"
import Title from "./title"
import Button from "./button"

const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  box-shadow: none;
  background: none;
  width: 20px;
  height: 14px;

  div {
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
  }

  @media (min-width: ${props => props.theme.breakPoints.sideMenu}) {
    display: none;
  }
`

const SideMenuWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 0.5s;
  transform: ${props => (props.isOpen ? "1" : "0")};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
`

const SideMenu = styled.div`
  position: relative;
  background: ${props => props.theme.colors.background};
  max-width: 300px;
  height: 100vh;
  padding: 10px;
  transition: transform 0.5s;
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(-100%)")};
  overflow: scroll;
`

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  align-items: center;
`

const NavListItem = styled.li`
  font-size: ${props => props.theme.fontSizes.headerMedium};
  padding: 20px;
`

const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  border: none;
  box-shadow: none;
  background: none;

  div {
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: ${props => props.theme.colors.primary};
    transform: rotate(45deg);
  }
  div:last-child {
    transform: rotate(-45deg);
  }
`

const StyledTitle = styled(Title)`
  padding: 100px 0;
`

const StyledButton = styled(Button)`
  //   min-width: initial;
  font-size: ${props => props.theme.fontSizes.headerMedium};
`

const Menu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }
  const closeMenu = event => {
    if (event.target !== event.currentTarget) {
      return
    }

    setMenuOpen(false)
  }

  return (
    <>
      <MenuButton onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </MenuButton>
      <SideMenuWrapper isOpen={isMenuOpen} onClick={closeMenu}>
        <SideMenu isOpen={isMenuOpen}>
          <CloseButton onClick={toggleMenu}>
            <div />
            <div />
          </CloseButton>
          <StyledTitle />
          <NavList>
            <NavListItem>
              <StyledLink to={`/`} activeClassName={"active"}>
                Strona główna
              </StyledLink>
            </NavListItem>
            <NavListItem>
              <StyledLink to={`/about`} activeClassName={"active"}>
                O mnie
              </StyledLink>
            </NavListItem>
            <NavListItem>
              <StyledLink to={`/offer`} activeClassName={"active"}>
                Oferta
              </StyledLink>
            </NavListItem>
            <NavListItem>
              <StyledLink to={`#contact`} activeClassName={"active"}>
                <StyledButton trackingId="Side menu - Make an Appointment">
                  Umów wizytę
                </StyledButton>
              </StyledLink>
            </NavListItem>
          </NavList>
        </SideMenu>
      </SideMenuWrapper>
    </>
  )
}

export default Menu
