import React from "react"
import styled from "styled-components"

const TitleWrapper = styled.div`
  color: ${props => props.theme.colors.primary};
  text-align: center;

  h1 {
    font-size: ${props => props.theme.fontSizes.headerMedium};
    font-weight: 400;
    margin: 0 -5px;
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.regular};
    font-weight: bold;
    margin: 0;
  }
`

const Title = ({ className }) => (
  <TitleWrapper className={className}>
    <h1>Aleksandra Styczeń</h1>
    <h2>psycholog psychoterapeuta</h2>
  </TitleWrapper>
)

export default Title
