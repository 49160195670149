export default {
  colors: {
    primary: "#E0896C",
    content: "#000000",
    background: "#ffffff",
    backgroundTransparent: "rgba(255, 255, 255, 0.85)",
    backgroundAccent: "#dddddd",
    highlight: "#000000",
  },
  fontSizes: {
    headerLarge: "36px",
    headerMedium: "24px",
    big: "18px",
    regular: "16px",
    mobile: "14px",
    small: "12px",
  },
  maxWidth: "1024px",
  breakPoints: {
    references: "500px",
    sideMenu: "700px",
    iPad: "768px",
  },
}
