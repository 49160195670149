import React from "react"
import styled from "styled-components"
import SectionHeader from "./section-header"

const Section = styled.section`
  position: relative;
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  background: ${props => props.theme.colors.backgroundTransparent};
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width: ${props => parseInt(props.theme.maxWidth) + 40}px) {
    padding: 0;
  }
`

const Anchor = styled.div`
  position: absolute;
  top: -70px;
`

const SectionWrapper = props => (
  <Section {...props}>
    {props.sectionId && <Anchor id={props.sectionId} />}
    {props.title && <SectionHeader>{props.title}</SectionHeader>}
    {props.children}
  </Section>
)

export default SectionWrapper
