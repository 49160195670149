import React from "react"
import styled, { css } from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const StyledButton = styled.button`
  display: block;
  text-align: center;
  border: none;
  box-shadow: none;
  padding: 10px;
  min-width: 240px;
  font-size: ${props => props.theme.fontSizes.regular};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.1s linear;
  border: 2px solid ${props => props.theme.colors.primary};

  ${props =>
    props.secondary &&
    css`
      background: none;
      color: ${props.theme.colors.primary};
      font-weight: bold;
    `}

  :hover {
    background-color: ${props => props.theme.colors.highlight};
    color: ${props => props.theme.colors.background};
    border: 2px solid ${props => props.theme.colors.highlight};

    ${props =>
      props.secondary &&
      css`
        font-weight: bold;
      `}
  }
`

const Button = props => {
  const handleClick = event => {
    trackCustomEvent({
      category: "Custom Button",
      action: "click",
      label: props.trackingId,
    })
  }

  return <StyledButton onClick={handleClick} {...props} />
}

export default Button
