import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;

  &.active {
    text-decoration: underline;
  }
`

export default StyledLink
